import React from 'react'
import style from "../../css/forum.module.css"

const Forum = () => {


  return (
    <div className={style.ForumField}>
    </div>
  )
}

export default Forum
